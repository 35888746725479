@use '../../base';

.burger-container {
  backdrop-filter: base.$blur;
  background: rgba($color: base.$primary-color, $alpha: .8);
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 3rem;
  justify-content: space-around;
  margin: .6rem;
  padding: .8rem;
  width: 3rem;
  z-index: 100;
}

.burger-container:hover {
  background: rgba($color: base.$white, $alpha: .8);
}

.burger {
  backdrop-filter: base.$blur;
  background: rgba($color: base.$white, $alpha: .8);
  border-radius: 1px;
  height: 4px;
  position: relative;
  transform-origin: left;
  transition: 300ms ease-in-out;
  width: 100%;
}

.burger-container:hover .burger {
  background: rgba($color: base.$primary-color, $alpha: .8);
}

.burger-container:active .burger {
  background: rgba($color: base.$primary-color, $alpha: .8);
}

.burger-x:first-of-type {
  transform: rotate(45deg);
}

.burger-x:nth-of-type(2) {
  opacity: 0;
  transform: translateX(100%);
}

.burger-x:last-of-type {
  transform: rotate(-45deg);
}

.close,
.open {
  position: absolute;
  right: 1px;
  top: 0;
  transition: 200ms ease-in-out;
}

.close {
  opacity: 0;
  transform: translateX(110%);
}

.open {
  opacity: 1;
  transform: translateX(0%);
}