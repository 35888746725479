@use "../../base.scss";

footer {
  background: base.$gradient-multi;
  margin-top: 5rem;
  max-height: auto;
  overflow: hidden;
  padding: 4rem 0;
  position: relative;
}

footer form {
  backdrop-filter: base.$blur;
  background: base.$transparent-primary;
  border-radius: base.$border-curve;
  box-shadow: base.$drop-shadow;
  margin: auto;
  max-width: 50ch;
  padding: 3rem 1rem;
}

form .bot-field-hidden {
  visibility: hidden;
  display: none;
}

footer fieldset > * {
  margin-top: 1rem;
}

footer fieldset {
  align-items: stretch;
  border: none;
  display: flex;
  flex-direction: column;
}

footer h2 {
  font-size: rem;
  margin: 0;
  text-align: center;
}

footer label,
footer input,
footer textarea {
  font-family: base.$body-fonts;
  width: 100%;
}

footer textarea,
footer input {
  color: base.$dark;
  font-size: 1rem;
  padding: 0.5rem;
}

footer textarea {
  height: 15ch;
  resize: vertical;
}

footer input {
  border: none;
  height: 3.5ch;
}

footer label {
  font-size: 1rem;
}

footer button {
  background: base.$dark;
  border: none;
  color: base.$white;
  cursor: pointer;
  font-family: base.$body-fonts;
  font-size: 1.4rem;
  padding: 1rem;
  width: 100%;
}

footer button:hover {
  background: base.$secondary-color;
  color: base.$white;
}

footer .social-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 4rem auto;
  max-width: 60ch;
}

.social-links a {
  backdrop-filter: base.$blur;
  border-radius: 15px;
  margin: 1rem 1.4rem;
}

.social-links svg {
  fill: base.$white;
  width: 80px;
}

.social-links svg:hover {
  fill: base.$primary-color;
}

footer .circle {
  background: base.$secondary-color;
  border-radius: 100%;
  height: calc(250px + 60vw);
  left: -25%;
  position: absolute;
  top: 10%;
  width: calc(250px + 60vw);
  z-index: -1;
}

.bot-field-hidden {
  visibility: hidden;
  display: none;
}
