@use "../../base.scss";

.header-grid {
  align-items: center;
  display: grid;
  grid-template-columns: 80px 1fr;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

nav {
  ul {
    display: flex;
    grid-column: 2 / -1;
    justify-content: flex-end;
    list-style: none;
    margin: 1rem 0;
  }
  
  ul a {
    backdrop-filter: base.$blur;
    background: base.$transparent-primary;
    border-radius: base.$border-curve;
    box-shadow: base.$drop-shadow;
    color: base.$white;
    cursor: pointer;
    display: block;
    margin-right: calc(0.6rem + 1vw);
    padding: 0.6rem 1.6rem;
    text-decoration: none;
  }

  ul a:active {
    background: rgba($color: base.$secondary-color, $alpha: 0.8);
  }

  ul a:hover {
    background: rgba($color: base.$white, $alpha: 0.8);
    border-radius: calc(base.$border-curve * 2);
    color: base.$primary-color;
    transform: scale(110%);
  }
}

.logo {
  max-width: 50px;
  z-index: 100;

  a {
    filter: drop-shadow(4px 4px 6px rgba($color: #000000, $alpha: 0.5));
    padding: 1rem;
  }

  svg {
    transition: 300ms ease-in-out;
  }

  svg:hover {
    filter: hue-rotate(180deg);
    transform: scale(110%);
  }
}

.burger-container {
  display: none;
  grid-column: 2 / -1;
  grid-row: 1 / 2;
  justify-self: flex-end;
}

@media (max-width: base.$tablet-size) {
  .desktop-menu {
    display: none;
  }

  .burger-container {
    display: flex;
  }
}
