@use "../../base.scss";

.example-page-cover {
  font-size: clamp(3.2em, 10vw, 8em);
  width: 100vw;
}

.example-page-cover img {
  margin: auto;
  object-fit: cover;
}

.example-page h1 {
  font-size: 0.5em;
  margin: 4rem 0.5rem 1rem 0.5rem;
}

.example-page h3 {
  margin-top: 1em;
}

.example-page figure {
  margin-top: 3rem;
}

.example-page-text {
  margin: auto;
  max-width: 80ch;
}

.embed {
  height: 70vh;
  margin: 1rem auto;
  width: 90%;
}

.example-page embed {
  display: block;
  height: 100%;
  width: 100%;
}

.example-page-link {
  display: flex;
  justify-content: center;
}

.example-page a:not(p > a) {
  background: base.$primary-color;
  color: base.$white;
  padding: 1.2rem 3.2rem;
  text-decoration: none;
}

.example-page a:hover {
  background: base.$white;
  color: base.$primary-color;
}

.example-page iframe {
  height: 400px;
  max-width: 100%;
  width: 1000px;
}

@media (max-width: base.$mobile-size) {
  
  .example-page iframe {
    height: 250px;
  }
}
