.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

// add media query for prefers reduced motion