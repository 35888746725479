@use "../../base.scss";

.mobile-menu-container {
  backdrop-filter: base.$blur;
  background: rgba($color: base.$dark, $alpha: .8);
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 80px 0 0 0;
  text-align: center;
  transition: 200ms ease-in-out;
}

.mobile-menu li a {
  margin: .5rem;
}