@use "base.scss";

* {
  box-sizing: border-box;
}

html {
  font-size: 18px;
  scroll-behavior: smooth;
}

body {
  background: base.$dark;
  color: base.$white;
  margin: 0;
}

p,
span,
a,
li,
form,
figcaption {
  font-family: base.$body-fonts;
}

p {
  line-height: 1.6rem;
  margin: 1rem 0.5rem 1.2rem 0.5rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: base.$header-fonts;
  letter-spacing: 2px;
  line-height: 100%;
  margin: 1.4em 0.5rem 0 0.5rem;
}

h1 {
  font-size: clamp(3.2em, 10vw, 8em);
  margin: 4rem 1rem 1rem 1rem;
}

h2 {
  font-size: clamp(2em, 5vw, 3.2em);
  text-align: center;
}

h3 {
  font-size: clamp(1.6em, 2.5vw, 2.6em);
}

h4 {
  font-size: clamp(1.2em, 1.25vw, 1.8em);
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

a {
  color: base.$primary-color;
  transition: 300ms ease-in-out;
}

a:hover {
  color: base.$white;
}

.centered-text {
  margin: 0 auto 5rem auto;
  max-width: 80ch;
}

figure {
  font-size: 0.8rem;
  margin: 0.5rem;
  text-align: right;
}

figcaption {
  margin: 0.5rem 0;
}

video {
  margin: 1rem auto;
  max-width: 100%;
}

.loading-page {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.loading-logo {
  animation: 1s bouncing 1500ms infinite;
  border-radius: 50%;
  height: 30px;
  opacity: 0;
  width: 30px;
}

.giphy-gif {
  border: none;
}

@keyframes bouncing {
  0% {
    border: solid 2px base.$white;
    opacity: 1;
    transform: scale(100%);
  }

  45% {
    background: base.$transparent-loader;
    border: solid 2px base.$primary-color;
    opacity: 1;
    transform: scale(200%);
  }

  100% {
    border: solid 2px base.$white;
    opacity: 1;
    transform: scale(100%);
  }
}
